@import "_recipes";
@import "_variables";


// TITLE
  .wysiwyg-title {
  	padding-top: 46px; padding-bottom: 26px; margin: 0;
    font-size: 32px; font-family: $medium; line-height: 1.1em;
    .domain-mesa-corporativa & { font-size: 24px;}
    .wysiwyg-notted { font-size: 1.25em;}
  }
// SUBTITLE
  .wysiwyg-subtitle {
  	padding: 16px 0px 14px; margin: 0;
    font-size: 22px; font-family: $medium; line-height: 1.1em;
    .domain-mesa-corporativa & { font-size: 20px;}
    &.separator {
    	margin: 30px -10px 10px; padding-left: 16px; padding-right: 16px;
    	background-color: $corporativoPrincipal;
    	color: $white; font-weight: normal;
    }
  }


// NOTTED
  .wysiwyg-notted { color: $corporativoPrincipal;}

// CONTENT
  .wysiwyg-content {
  	max-width: 580px;
  	margin: 0 auto; padding-bottom: 24px;
  	font-size: 18px; line-height: 1.1em;
  	.domain-mesa-corporativa & { font-size: 16px;}
  	img {
  		display: inline-block;
			margin: 10px;
  	}
  }

// DEFAULT
  .wysiwyg-default { font-size: 14px; line-height: 1.1em; }
  .vertical-separation { color: $corporativoPrincipal; font-weight: bold;}

// UL DEFAULT
	div[class*='field-name-field-longtext'] {
		ul:not(.tips) {
			margin: 0; padding: 0;
			li {
				position: relative;
				margin: 6px 0; padding: 0 0 0 35px;
				list-style: none;
				line-height: 1.1em; //font-size: 18px;
				&:before {
					content: "\e907";
					@include icomoon(14px);
					position: absolute; left: 0px; top: 2px;
					color: $corporativoPrincipal;
				}
			}
		}
	}

// TEMPLATE
	.list-template {
		display: table; width: 100%;
		padding: 90px 0 100px;
		> div { display: table-cell; vertical-align: top;}
		.list-left {
			width: 310px;
			padding-left: 105px;
			text-align: right;
		}
		.list-right { padding-left: 50px;}
		.title-notted {
			margin: 0;
			font-size: 32px; line-height: 1.1em;
		}
		ul {
			margin: 0; padding: 0;
			li {
				position: relative;
				margin: 12px 0; padding: 0 0 0 50px;
				list-style: none;
				font-size: 18px; line-height: 1.1em;
				&:before {
					content: "\e904\e904\e904";
					@include icomoon(16px);
					position: absolute; left: 0px;
					color: $corporativoPrincipal;
				}
			}
		}
		&.red-code {
			color: $white;
			ul {
				li {
					&:before { color: #918684;}
				}
			}
		}
	}

	.title-red-code {
		padding-top: 10px;
		font-size: 30px; font-family: $condensedmedium; font-weight: normal;
		strong { font-weight: normal; color: $corporativoPrincipal; font-size: 1.2em;}
	}

	span.wysiwyg-text-title {
		font-size: 24px; font-family: $medium; line-height: 1.1em;
		display: block; margin-bottom: 0px;
	}
	span.grey-text {
		color: #828282;
	}

//* ********** RESPONSIVE
	@media all and (max-width: 1023px) {
		.list-template {
			.list-left { padding-left: 0;}
		}
	}
	@media all and (max-width: 767px) {
	  .wysiwyg-title { font-size: 28px;}
	  .wysiwyg-subtitle { font-size: 20px;}
		.list-template {
			display: block;
			padding-top: 70px; padding-bottom: 80px;
			> div { display: block;}
			.list-left {
				width: 100%;
				margin-bottom: 30px;
				text-align: center;
			}
			.list-right { padding-left: 0px;}
		}
	}

